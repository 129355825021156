<template>
  <div>
    <b-card
      bg-variant="light"
      header="Mis Animales"
      header-bg-variant="primary"
      header-text-variant="white"
      align="center"
    >
      <b-col cols="12" class="text-right">
        <b-button
          class="vertical-align"
          variant="secondary"
          @click="abrirModalAgregar"
        >
          <b-icon icon="plus-circle"></b-icon>
          Agregar nueva mascota
        </b-button>
      </b-col>

      <br /><br />

      <b-row v-if="animalesServicio.length > 0">
        <b-col>
          <b-table
            id="consultaLicencias"
            striped
            responsive
            small
            bordered
            :items="animalesServicio"
            :fields="camposTabla"
            :per-page="perPage"
            :current-page="currentPage"
            :tbody-tr-class="rowClass"
            select-mode="single"
            ref="selectableTable"
            selectable
            @row-selected="onRowSelected"
          >
            <template #cell(seleccionar)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row v-if="animalesServicio.length > 0">
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="consultaLicencias"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="ModalAgregar"
      v-model="mostrarModalAgregar"
      hide-footer
      size="lg"
    >
      <template #modal-header>
        <h5>Agregar Mascota</h5>
        <b-button @click="cancelar(false)" variant="link"> x </b-button>
      </template>
      <div class="d-block text-center p-3">
        <FormularioAgregarAnimal
          @resetear="cancelar"
          :animalSeleccionadoAgregar="animalSelec"
          :vistaVeterinario="false"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { encontrarError } from "../../../contantesErrores";
import FormularioAgregarAnimal from "../../../components/animales/consultaAnimales/FormularioAgregarAnimal";
import moment from "moment";
export default {
  name: "MisAnimales",
  components: {
    FormularioAgregarAnimal,
  },
  data() {
    return {
      mostrarModalAgregar: false,
      cerrarModal: false,
      animalSelec: {},
      persona: {},
      camposTabla: [
        {
          key: "nombre",
          label: "Nombre Mascota",
        },
        {
          key: "descripcion_tipo_animal",
          label: "Tipo Animal",
        },
        {
          key: "nombre_raza",
          label: "Raza",
        },
        {
          key: "sexo",
          formatter: "mostrarSexo",
        },
        {
          key: "fecha_nacimiento",
          label: "Edad",
          formatter: "calcularEdad",
        },
      ],
      perPage: 10,
      currentPage: 1,
    };
  },
  mounted() {
    if (localStorage.getItem("persona")) {
      try {
        this.persona = JSON.parse(localStorage.getItem("persona"));
        this.cargarMisMascotas();
        if(this.persona.nombre_persona == ' '){
          this.modalMensaje('Debe diligenciar los datos personales para continuar.')
        }
      } catch (e) {
        localStorage.removeItem("persona");
      }
    }
  },
  computed: {
    ...mapState("animales", ["animalesServicio"]),
    rows() {
      return this.animalesServicio.length;
    },
  },
  methods: {
    ...mapActions("animales", ["buscarAnimales", "seleccionarAnimal", "resetAtencionesVeterinariasTabla"]),

    async cargarMisMascotas(){
      console.log('la persona',this.persona)
      let filtros = {
        animalparams: {
          identificacion: this.persona.login,
        },
      };
      try {
        await this.buscarAnimales(filtros);
      } catch (e) {
        encontrarError(this, e.data);
      }
    },

    mostrarSexo(value) {
      if (value == 2) {
        return "Hembra";
      } else {
        return "Macho";
      }
    },

    calcularEdad(value) {
      let edad = null;
      if (value != null) {
        let fechaNacimiento = moment(value, "YYYY-MM-DD");
        let fechaActual = moment();
        edad = fechaActual.diff(fechaNacimiento, "years");
      }
      return edad;
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      return "rowspace";
    },

    onRowSelected(items) {
      if (items.length > 0) {
        this.seleccionarAnimal(items[0]);
        this.animalSelec=items[0];
        this.abrirModalAgregar()
      } else {
        this.seleccionarAnimal({});
        this.animalSelec={};
      }
    },

    cancelar(guardar) {
      this.resetAtencionesVeterinariasTabla()
      this.seleccionarAnimal({});
      this.animalSelec={};
      this.cerrarModalAgregar();
      if (guardar == true) {
        this.cargarMisMascotas();
      }
    },

    cerrarModalAgregar(){
      console.log('tal vez aqui')
      this.cerrarModal=true
      this.$bvModal.hide('ModalAgregar')
      
    },

    abrirModalAgregar() {
      this.mostrarModalAgregar = true;
    },

    modalMensaje(mensaje) {
      this.$bvModal
        .msgBoxOk(mensaje, {
          title: "Error",
          size: "md",
          buttonSize: "md",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(() => {
          this.$router.push({ name: "misDatos" });
        })
        .catch((err) => {
          console.log(err);
        });
    }, 
  },
};
</script>